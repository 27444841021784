<div class="container">
    <h1 class="mt-3">Edit Technician</h1>

    <ng-container *ngIf="model">
        <form #form="ngForm" (ngSubmit)="OnFormSubmit()">

            <div class="mt-2 mb-3">

                <div class="mt-3">
                    <label for="name" class="form-label"> Name </label>
                    <input type="text" id="name" class="form-control" name="name" [(ngModel)]="model.name">
                </div>

                <div class="mt-3">
                    <label for="email" class="form-label"> Email </label>
                    <textarea id="email" class="form-control" name="email" [(ngModel)]="model.email"></textarea>
                </div>

                <div class="mt-3">
                    <label for="phone" class="form-label"> Phone </label>
                    <input type="number" id="phone" class="form-control" name="phone" [(ngModel)]="model.phone">
                </div>

                <div class="mt-3">
                    <input type="checkbox" id="isAdmin" class="form-check-input" name="isAdmin" [(ngModel)]="model.isAdmin">
                    <label for="isAdmin" class="form-check-label">Is Admin</label>
                </div>

                <div class="mt-3 d-flex">
                    <button class="btn btn-primary" type="submit">Save</button>
                    <!-- <button class="btn btn-danger ms-3" (click) ="onDelete()">Delete</button> -->
                </div>


            </div>

        </form>
    </ng-container>
</div>
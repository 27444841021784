import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpParams } from '@angular/common/http';
import { AddTicketRequest } from '../models/add-ticket-request.model';
import { Observable } from 'rxjs';
import { Ticket } from '../models/ticket.model';
import { environment } from '../../../../environments/environment.development';
import { UpdateTicketRequest } from '../models/update-ticket-request.model';
import { CookieService } from 'ngx-cookie-service';
import { tick } from '@angular/core/testing';
import { extraFilters } from '../models/extraFilters';
@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient,
    private cookieService: CookieService) { }

  AddTicket(model: AddTicketRequest): Observable<void> {
    return this.http.post<void>(`${environment.apiBaseUrl}/api/ticket`, model, {
      headers: {
        'Authorization': this.cookieService.get('Authorization')
      }
    });
  }

  getAllTickets(query?: string, sortBy?: string, sortDirection?: string,
    pageNumber?: number, pageSize?: number, status?: number[], userId?: string,
    extraFilters?: extraFilters
  ): Observable<Ticket[]> {
    let params = new HttpParams();

    if (query) {
      params = params.set('query', query);
    }
    if (sortBy) {
      params = params.set('sortBy', sortBy);
    }
    if (sortDirection) {
      params = params.set('sortDirection', sortDirection);
    }
    if (pageNumber) {
      params = params.set('pageNumber', pageNumber);
    }
    if (pageSize) {
      params = params.set('pageSize', pageSize);
    }
    if (status != undefined) {
      params = params.append('status', status.join(', '));
    }
    if (userId != undefined) {
      params = params.append('filterByUser', userId);
    }
    if (extraFilters) {
      // Serialize ExtraFilters object
      if (extraFilters.paymentFilter) {
        params = params.set('extraFilters.paymentFilter', extraFilters.paymentFilter);
      }
      if (extraFilters.otpVerifiedFilter) {
        params = params.set('extraFilters.otpVerifiedFilter', extraFilters.otpVerifiedFilter);
      }
    }

    return this.http.get<Ticket[]>(`${environment.apiBaseUrl}/api/Ticket`, {
      params: params
    });
  }

  getTicketById(id?: string): Observable<Ticket> {
    return this.http.get<Ticket>(`${environment.apiBaseUrl}/api/Ticket/${id}`);
  }

  getTicketCount(): Observable<number> {
    return this.http.get<number>(`${environment.apiBaseUrl}/api/Ticket/count`);
  }

  updateTicket(id: string, updateTicketRequest: UpdateTicketRequest): Observable<Ticket> {
    return this.http.put<Ticket>(`${environment.apiBaseUrl}/api/Ticket/${id}`, updateTicketRequest, {
      headers: {
        'Authorization': this.cookieService.get('Authorization')
      }
    });
  }

  SendOtpToCustomer(ticket: Ticket): Observable<Ticket> {
    return this.http.post<Ticket>(`${environment.apiBaseUrl}/api/Ticket/sendOTP`, ticket, {
      headers: {
        'Authorization': this.cookieService.get('Authorization')
      }
    });
  }
  VerifyOTP(id: string, otp: string): Observable<Ticket> {
    return this.http.post<Ticket>(
        `${environment.apiBaseUrl}/api/Ticket/verifyOTP/${id}`,
        `"${otp}"`, // Wrap the otp string in double quotes to send it as a plain string
        {
            headers: {
                'Authorization': this.cookieService.get('Authorization'),
                'Content-Type': 'application/json'
            }
        }
    );
}


  
  
  deleteTicket(id: string): Observable<Ticket> {
    return this.http.delete<Ticket>(`${environment.apiBaseUrl}/api/Ticket/${id}`, {
      headers: {
        'Authorization': this.cookieService.get('Authorization')
      }
    });
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TicketHistory } from '../../model/ticketHistory.model';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TicketHistoryService {

  constructor(private http: HttpClient,
    private cookieService: CookieService) { }

    getAllHistory(startDate?: Date, endDate?: Date, pageNumber?: number, pageSize?: number): Observable<TicketHistory[]> {
      let params = new HttpParams();
      
      if (startDate != undefined) {
        params = params.append('startDate', startDate.toISOString());
      }
      
      if (endDate != undefined) {
        params = params.append('endDate', endDate.toISOString());
      }
      if (pageNumber) {
        params = params.set('pageNumber', pageNumber);
      }
      if (pageSize) {
        params = params.set('pageSize', pageSize);
      }
      
      return this.http.get<TicketHistory[]>(`${environment.apiBaseUrl}/api/TicketHistory`, { params });
    }

    getHistoryCount(): Observable<number> {
      return this.http.get<number>(`${environment.apiBaseUrl}/api/TicketHistory/count`);
    }
}

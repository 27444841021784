import { Component } from '@angular/core';
import { LoginRequest } from '../Models/login-request.model';
import { FormsModule } from '@angular/forms';
import { ColdObservable } from 'rxjs/internal/testing/ColdObservable';
import { AuthService } from '../services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Route, Router } from '@angular/router';
import { LoaderComponent } from "../../loader/loader.component";
import { throws } from 'assert';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.css',
    imports: [FormsModule, LoaderComponent]
})
export class LoginComponent {

  model: LoginRequest;
  isLoading = false;
  constructor(private authService: AuthService,
    private cookieService : CookieService,
    private router: Router){
    this.model = {
      email :'',
      password :''
    };
  }

  onFormSubmit(): void{
    this.isLoading = true;
    this.authService.login(this.model).subscribe({
      next: (response) => {
        this.isLoading = false;
        // set auth cookie
        this.cookieService.set('Authorization', `Bearer ${response.token}`, 
        undefined, '/', undefined, true, 'Strict');
    
        this.authService.setUser({
          email: response.email,
          roles: response.roles
        });
    
        // redirect to home page
        this.router.navigateByUrl('/admin/tickets');
      },
      error: (error) => {
        // show alert to user that login failed
        alert('Login failed. Please check your credentials and try again.');
        this.isLoading = false;
      }
    });
    
  }
}

<div class="container">
    <h1 class="text-center my-5"
    style="color: #ef383c; font-family: 'Times New Roman', serif; font-size: 3em; text-shadow: 1px 1px 0px rgb(240, 235, 235);">
    EDIT TICKET</h1>
    <app-loader [isLoading]="isLoading"></app-loader>

    <ng-container *ngIf="ticket; else notFound">
        <form #form="ngForm" (ngSubmit)="OnFormSubmit(form)">
            <div class="mt-2">
                <div class="mt-3">
                    <label class="form-label" for="ticketId"><strong>Ticket Id</strong></label>
                    <input type="text" disabled id="TicketId" class="form-control" name="TicketId"
                        [(ngModel)]="ticket.displayId">
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <label class="form-label" for="customer"><strong>Customer</strong></label>
                    <input type="text" disabled id="customer" class="form-control" name="customer"
                        [(ngModel)]="ticket.customer.name">
                </div>
                <div class="col">
                    <label class="form-label" for="customerAddress"><strong>Customer Address</strong></label>
                    <input type="text" disabled id="customerAddress" class="form-control" name="customerAddress"
                        [(ngModel)]="ticket.customer.address">
                </div>
                <div class="col">
                    <label class="form-label" for="customerPhone"><strong>Customer Phone</strong></label>
                    <input type="text" disabled id="customerPhone" class="form-control" name="customerPhone"
                        [(ngModel)]="ticket.customer.phone">
                </div>
                <div class="col">
                    <label for="ticketStatus" class="form-label" style="margin-bottom: 10px;"><strong>Ticket Status</strong></label>
                    <select [(ngModel)]="ticket.status" name="ticketStatus" id="ticketStatus" class="form-select"
                        style="background-color:#d70b0b; color: white;" (click)="OnStatusChange(ticket.status)">
                        <option *ngFor="let status of keys" [ngValue]="getValueFromKey(status)">{{ status }}</option>
                    </select>
                </div>
            </div>
            <div *ngIf="(showOTP || !this.ticket?.isOtpVerified) && this.ticket.status == ticketStatus.Closed">
                <div class="row mt-3">
                    <div class="col">
                        <label class="form-label" for="otp"><strong>OTP</strong></label>
                        <input id="otp" [(ngModel)]="UserOTP" class="form-control" name="otp" />
                        <button type="button" class="mt-3 btn btn-danger me-2"
                            (click)="sendOtpToCustomer(SendOTPTemplate)"><strong>Send OTP to Customer</strong></button>
                        <button type="button" class="mt-3 btn btn-success" (click)="verifyOtp()">Verify OTP</button>
                    </div>
                </div>
            </div>
            <div *ngIf="this.ticket?.isOtpVerified" class="text-success font-weight-bold mt-2">
                <span><i class="bi bi-patch-check"></i> <strong>OTP Verified</strong></span>
            </div>

            <div *ngIf="showCancelledReason">
                <div class="row mt-3">
                    <div class="col">
                        <label class="form-label" for="cancelledReason"><strong>Reason For Cancellation</strong></label>
                        <input type="text" class="form-control" name="cancelledReason"
                            [(ngModel)]="ticket.cancellationReason" required>
                        <div *ngIf="form.submitted || form.controls['cancelledReason']?.invalid">
                            <span class="text-danger" *ngIf="form.controls['cancelledReason']?.errors?.['required']">
                                Cancellation Reason is required.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mt-2">
                <div class="mt-3">
                    <label class="form-label" for="ticketDescription"><strong>Query</strong></label>
                    <textarea disabled ="ticketDescription" class="form-control" name="ticketDescription" rows="5"
                        [(ngModel)]="ticket.description"></textarea>
                </div>
            </div>
            <div *ngIf="showTechnician">
                <div class="row mt-3">
                    <div class="col">
                    <label for="ticketStatus" class="form-label" style="margin-bottom: 10px;"><strong>Technician</strong></label>
                    <ng-container *ngIf="user$ | async as users">
                        <select class="form-control" [(ngModel)]="selectedUser" name="users" id="users" required>
                            <option value="" disabled selected>Select a technician</option>
                            <option *ngFor="let user of users" [ngValue]="user.id">{{ user.name }}</option>
                        </select>
                        <div *ngIf="form.submitted || form.controls['users']?.invalid">
                            <span class="text-danger" *ngIf="form.controls['users'].errors?.['required']">
                                Technician selection is required.
                            </span>
                        </div>
                    </ng-container>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <label class="form-label"><strong>Created At</strong></label>
                    <input type="text" class="form-control readonly-input" [value]="formatDate(ticket.createdAt)"
                        readonly>
                </div>
                <div class="col">
                    <label class="form-label"><strong>Updated At</strong></label>
                    <input type="text" class="form-control readonly-input" [value]="formatDate(ticket.updatedAt)"
                        readonly>
                </div>
                <div class="col">
                    <label class="form-label"><strong>Visiting Charge</strong></label>
                <input type="text" class="form-control readonly-input" [value]="ticket.visitingCharge"
                    readonly>
                </div>
                <div class="col">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label><strong>Scheduled date</strong></mat-label>
                        <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="ticket.scheduledDate" (dateChange)="setScheduledDate($event)" name="scheduledDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                </div>
            </div>
            <div class="switcher">
                <app-switcher [initialState]="this.ticket.isPaymentDone"
                    (sendSwitcherState)="handleSwitcherChange($event)"></app-switcher>
            </div>
           

            <div class="d-flex justify-content-center align-items-center">
                <div class=" mb-3"  style="display: flex; align-items: center;">
                    <button type="button" class="btn btn-danger" (click)="openModal(SubmitTemplate)"
                        [disabled]="isSubmitButtonDisabled" [disabled]="isLoading">
                        Submit
                    </button>
                    <app-loader [isLoading]="isLoading"></app-loader>
                </div>
            </div>
            <!-- modal section within the form -->
            <ng-template #SubmitTemplate>
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Submit Confirmation</h5>
                        <button type="button" class="btn-close" aria-label="Close" (click)="exitModal()"></button>
                    </div>
                    <div class="modal-body">
                        <p>Do you want to submit this?</p>
                        <div *ngIf="this.informCustomerofTicketStatusChangedNewToInProgressBox" class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="modalCheckbox1"
                                [(ngModel)]="whatsappMsg.informCustomerofTicketStatusChangedNewToInProgress"
                                name="modalCheckbox1">
                            <label class="form-check-label" for="modalCheckbox1">Inform Customer of Ticket Status</label>
                        </div>
                        <div *ngIf="this.InformCustomerTicketClosedBox" class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="modalCheckbox2"
                                [(ngModel)]="whatsappMsg.InformCustomerTicketClosed" name="modalCheckbox2">
                            <label class="form-check-label" for="modalCheckbox2">Inform Customer Ticket Closed</label>
                        </div>
                        <div *ngIf="this.informUserOfTicketCreationBox" class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="modalCheckbox3"
                                [(ngModel)]="whatsappMsg.informUserOfTicketCreation" name="modalCheckbox3">
                            <label class="form-check-label" for="modalCheckbox3">Inform Technician About Ticket</label>
                        </div>
                        <div *ngIf="this.InformCustomerTicketCancelledBox" class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="modalCheckbox4"
                                [(ngModel)]="whatsappMsg.InformCustomerTicketCancelled" name="modalCheckbox4">
                            <label class="form-check-label" for="modalCheckbox4">Inform Customer Ticket Cancelled</label>
                        </div>
                        <div *ngIf="this.informUserTicketCancelledBox" class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="modalCheckbox5"
                                [(ngModel)]="whatsappMsg.informUserTicketCancelled" name="modalCheckbox5">
                            <label class="form-check-label" for="modalCheckbox5">Inform Technician Ticket Cancelled</label>
                        </div>
                        <div *ngIf="this.InformUserTicketClosedBox" class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="modalCheckbox6"
                                [(ngModel)]="whatsappMsg.informUserTicketClosed" name="modalCheckbox6">
                            <label class="form-check-label" for="modalCheckbox6">Inform Technician Ticket Closed</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" (click)="exitModal()">Close</button>
                        <button type="submit" class="btn btn-primary" (click)="OnFormSubmit(form)">Submit</button>
                    </div>
                </div>
            </ng-template>
        </form>
    </ng-container>
    <ng-template #notFound>
        <div class="alert alert-warning" role="alert">
            Ticket Not Found!
        </div>
    </ng-template>
</div>

<ng-template #SendOTPTemplate>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">OTP Notification</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="exitModal()"></button>
        </div>
        <div class="modal-body p-4 text-dark">
            <p>OTP is sent to customer</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="exitModal()">Close</button>
        </div>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { AddCustomer } from '../model/add-customer.model';
import { Observable } from 'rxjs';
import { Customer } from '../model/customer.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment.development';
import { UpdateCustomerRequest } from '../model/update-ticket-request.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http:HttpClient ,
    private cookieService : CookieService) { }

  createCustomer(data: AddCustomer):Observable<Customer>{
    return this.http.post<Customer>(`${environment.apiBaseUrl}/api/customer`, data ,{
      headers : {
        'Authorization': this.cookieService.get('Authorization')
      }
    });
  }

  getAllCustomers(query?: string, sortBy?: string, sortDirection?: string,
    pageNumber?: number , pageSize?: number):Observable<Customer[]>{


      let params = new HttpParams();

    if(query){
      params = params.set('query', query);
    }
    if(sortBy){
      params = params.set('sortBy', sortBy);
    }
    if(sortDirection){
      params = params.set('sortDirection', sortDirection);
    }
    if(pageNumber){
      params = params.set('pageNumber', pageNumber);
    }
    if(pageSize){
      params = params.set('pageSize', pageSize);
    }
    return this.http.get<Customer[]>(`${environment.apiBaseUrl}/api/customer`, {
      params: params
    });
  }

  getCustomerById(id:string):Observable<Customer>{
    return this.http.get<Customer>(`${environment.apiBaseUrl}/api/customer/${id}`);
  }

  getCustomerCount() : Observable<number>{
    return this.http.get<number>(`${environment.apiBaseUrl}/api/customer/count`);
  }

  updateCustomer(id: string, updateCustomerRequest : UpdateCustomerRequest): Observable<Customer>{
    return this.http.put<Customer>(`${environment.apiBaseUrl}/api/customer/${id}`, updateCustomerRequest,{
      headers : {
        'Authorization': this.cookieService.get('Authorization')
      }
    });
  }
  deleteCustomer(id: string): Observable<Customer>{
    return this.http.delete<Customer>(`${environment.apiBaseUrl}/api/customer/${id}`,{
      headers : {
        'Authorization': this.cookieService.get('Authorization')
      }
    });
  }
}

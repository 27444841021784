<div class="container">
    <h1 class="mt-3">Edit Customer</h1>

    <ng-container *ngIf="model">
        <form #form="ngForm" (ngSubmit)="OnFormSubmit()">

            <div class="mt-2 mb-3">

                <div class="mt-3">
                    <label for="name" class="form-label"> Name </label>
                    <input type="text" id="name" class="form-control" name="name" [(ngModel)]="model.name">
                </div>

                <div class="mt-3">
                    <label for="address" class="form-label"> Address </label>
                    <textarea id="address" class="form-control" name="address" [(ngModel)]="model.address"></textarea>
                </div>

                <div class="mt-3">
                    <label for="phone" class="form-label"> Phone </label>
                    <input type="number" id="phone" class="form-control" name="phone" [(ngModel)]="model.phone">
                </div>

                <div class="mt-3">
                    <label for="alternatePhone" class="form-label"> Alternate Phone </label>
                    <input type="number" id="alternatePhone" class="form-control" name="alternatePhone" [(ngModel)]="model.alternatePhone">
                </div>

                <div class="mt-3">
                    <label for="imageUrl" class="form-label"> image Url </label>
                    <input type="text" id="imageUrl" class="form-control" name="imageUrl" [(ngModel)]="model.imageUrl">
                </div>

                <div class="mt-3" style="display: flex; align-items: center;">
                    <button class="btn btn-primary" type="submit" [disabled]="isLoading">Save</button>
                    <app-loader [isLoading]="isLoading"></app-loader>
                </div>


            </div>

        </form>
    </ng-container>
</div>
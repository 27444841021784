<div class="container">
    <h1 class="mt-3">Add Ticket</h1>
    <form #form="ngForm" (ngSubmit)="OnFormSubmit(form)">
        <!-- Customer Section -->
        <div class="mt-2">
            <div class="mt-3">
                <label for="customerSearch" class="form-label">Customer</label>
                <input
                    type="text"
                    name="customerSearch"
                    id="customerSearch"
                    class="form-control"
                    [(ngModel)]="searchTerm"
                    (ngModelChange)="filterCustomers()"
                    placeholder="Search for a customer"
                />
                <div class="filtered-customers-container mt-2" *ngIf="filteredCustomers.length > 0 && selectedCustomer==null">
                    <ul class="list-group">
                        <li *ngFor="let customer of filteredCustomers" class="list-group-item" (click)="selectCustomer(customer)">
                            {{ customer.name + "    ,    " + customer.phone + "    ,    " + customer.address }}
                        </li>
                    </ul>
                </div>
                <div class="mt-3" *ngIf="selectedCustomer">
                    <p><strong>Name:</strong> {{ selectedCustomer.name }}</p>
                    <p><strong>Address:</strong> {{ selectedCustomer.address }}</p>
                    <p><strong>Phone Number:</strong> {{ selectedCustomer.phone }}</p>
                </div>
            </div>
        </div>
        <div class="mt-2" *ngIf="selectedCustomer==null">
            <button type="button" class="btn btn-dark mt-3"  (click)="addCustomer()">Add Customer</button>
        </div>
        <!-- New Customer Section -->
        <div *ngIf="showNewCustomerForm && selectedCustomer == null">
            <form #newCustomerForm="ngForm" (ngSubmit)="saveNewCustomer(newCustomerForm)">
                <div class="mt-3">
                    <label class="form-label" for="newCustomerName">Customer Name</label>
                    <input type="text" class="form-control" [(ngModel)]="newCustomer.name" name="newCustomerName"
                        required>
                    <div *ngIf="newCustomerForm.submitted && newCustomerForm.controls['newCustomerName']?.invalid">
                        <span class="text-danger"
                            *ngIf="newCustomerForm.controls['newCustomerName']?.errors?.['required']">
                            Customer Name is required.
                        </span>
                    </div>
         
                    <div class="form-group">
                        <label class="form-label" for="newCustomerAddress">Customer Address</label>
                        <textarea class="form-control" id="newCustomerAddress" [(ngModel)]="newCustomer.address"
                            name="newCustomerAddress" rows="4" required></textarea>
                        <div
                            *ngIf="newCustomerForm.submitted && newCustomerForm.controls['newCustomerAddress']?.invalid">
                            <span class="text-danger"
                                *ngIf="newCustomerForm.controls['newCustomerAddress']?.errors?.['required']">
                                Customer Address is required.
                            </span>
                        </div>
                        <small class="form-text text-muted">Please provide the address of the customer.</small>
                    </div>
         
                    <br>
         
                    <label class="form-label" for="newCustomerPhone">Customer Phone Number</label>
                    <input type="text" class="form-control" [(ngModel)]="newCustomer.phone" name="newCustomerPhone"
                        required minlength="10" maxlength="10" pattern="^[0-9]*$">
                    <div *ngIf="newCustomerForm.submitted && newCustomerForm.controls['newCustomerPhone']?.invalid">
                        <span class="text-danger"
                            *ngIf="newCustomerForm.controls['newCustomerPhone']?.errors?.['required']">
                            Customer Phone Number is required.
                        </span>
                        <span class="text-danger"
                            *ngIf="newCustomerForm.controls['newCustomerPhone']?.errors?.['minlength']">
                            Phone number must be exactly 10 digits long.
                        </span>
                        <span class="text-danger"
                            *ngIf="newCustomerForm.controls['newCustomerPhone']?.errors?.['maxlength']">
                            Phone number must be exactly 10 digits long.
                        </span>
                        <span class="text-danger"
                            *ngIf="newCustomerForm.controls['newCustomerPhone']?.errors?.['pattern']">
                            Phone number must contain only digits.
                        </span>
                    </div>
                    <small class="form-text text-muted">Please provide the 10 digit phone number of the customer
                        (Without country code).</small>
         
                        <br>
                        <br>
         
                        <label class="form-label" for="newCustomerAlternatePhone">Customer Alternate Phone Number</label>
                        <input type="text" class="form-control" [(ngModel)]="newCustomer.alternatePhone" name="newCustomerAlternatePhone"
                             minlength="10" maxlength="10" pattern="^[0-9]*$">
                        <div *ngIf="newCustomerForm.submitted && newCustomerForm.controls['newCustomerAlternatePhone']?.invalid">
                            <span class="text-danger"
                                *ngIf="newCustomerForm.controls['newCustomerAlternatePhone']?.errors?.['minlength']">
                                Phone number must be exactly 10 digits long.
                            </span>
                            <span class="text-danger"
                                *ngIf="newCustomerForm.controls['newCustomerAlternatePhone']?.errors?.['maxlength']">
                                Phone number must be exactly 10 digits long.
                            </span>
                            <span class="text-danger"
                                *ngIf="newCustomerForm.controls['newCustomerAlternatePhone']?.errors?.['pattern']">
                                Phone number must contain only digits.
                            </span>
                        </div>
                        <small class="form-text text-muted">Please provide the 10 digit alternate phone number of the customer
                            (Without country code).</small>
                </div>
         
                <button type="submit" class="btn btn-primary mt-3">Save Customer</button>
            </form>
        </div>

        <!-- Ticket Section -->
        <div class="mt-2">
            <div class="mt-3">
                <label class="form-label" for="ticketDescription">Query</label>
                <textarea id="ticketDescription" class="form-control" name="ticketDescription"
                    [(ngModel)]="model.description" rows="4" required></textarea>
                <div *ngIf="form.submitted && form.controls['ticketDescription']?.invalid">
                    <span class="text-danger" *ngIf="form.controls['ticketDescription']?.errors?.['required']">Query is
                        required.</span>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Scheduled date</mat-label>
                <input matInput [matDatepicker]="picker" placeholder="Choose a date" [(ngModel)]="model.scheduledDate" (dateChange)="setScheduledDate($event)" name="scheduledDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
        </div>
        <div class="mt-3">
            <label for="notifyCustomer" class="form-check-label">Notify Customer <i class="fa fa-whatsapp"></i> </label>
            <input type="checkbox" class="form-check-input ms-2" id="notifyCustomer" [(ngModel)]="model.notifyCustomer"
                name="notifyCustomer">
        </div>
        <div class="mt-3">
            <label for="visitingCharge" class="form-check-label">Visiting charge <i class="fa fa-whatsapp"></i> </label>
            <input type="checkbox" class="form-check-input ms-2" id="visitingCharge" [(ngModel)]="model.visitingCharge"
                name="visitingCharge">
        </div>
        <div *ngIf="model.visitingCharge == true" class="mt-3 row align-items-center">
            <label for="visitingChargeAmount" class="form-label col-auto">Visiting charge Amount <i
                    class="fa fa-whatsapp"></i></label>
            <div class="col-auto">
                <input type="number" class="form-control" id="visitingChargeAmount"
                    [(ngModel)]="model.visitingChargeAmount" name="visitingChargeAmount" style="width: auto;" size="10">
            </div>
        </div>

        <div class="mt-3" style="display: flex; align-items: center;">
            <button class="btn btn-primary" type="submit" [disabled]="isLoading">Save</button>
            <app-loader [isLoading]="isLoading"></app-loader>
        </div>
    </form>
</div>
import { Component } from '@angular/core';
import { AddCustomer } from '../model/add-customer.model';
import { FormsModule } from '@angular/forms';
import { CustomerService } from '../service/customer.service';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { catchError, of } from 'rxjs';
import { LoaderComponent } from "../../loader/loader.component";

@Component({
    selector: 'app-add-customer',
    standalone: true,
    templateUrl: './add-customer.component.html',
    styleUrl: './add-customer.component.css',
    imports: [FormsModule, CommonModule, LoaderComponent]
})
export class AddCustomerComponent {
  model: AddCustomer;
  isLoading = false;

  constructor(private customerService: CustomerService, private router: Router) {
    this.model = {
      name: '',
      address: '',
      imageUrl: '',
      phone: '',
      alternatePhone: ''
    }
  }

  OnFormSubmit(form: any): void {
    if (form.valid) {
      this.isLoading = true;
      this.model.phone = this.model.phone.toString();
      this.customerService.createCustomer(this.model)
        .pipe(
          catchError((error: any) => {
            let errorMessage = 'An unexpected error occurred';
    
            // Check if the error status is 400 and the error contains customer details
            if (error.status === 400 && error.error) {
              const existingCustomer = error.error;
              errorMessage = `This customer already exists. Here are the existing customer details:\nName: ${existingCustomer.name}\nPhone: ${existingCustomer.phone}\nAddress: ${existingCustomer.address }`;
            } else {
              errorMessage = `An unexpected error occurred: ${error.message}`;
            }
    
            alert(errorMessage);
            return of(null); // Return a safe value or handle it appropriately
          })
        )
        .subscribe({
          next: (response) => {
            if (response) {
              this.router.navigateByUrl('admin/customers');
            }
            this.isLoading = false;
          }
        });
    }
    else{
      this.isLoading = false;
    }
  }
}

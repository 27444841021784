<div class="container">
    <h1 class="text-center my-5" style="color: #ef383c; font-family: 'Times New Roman', serif; font-size: 3em; text-shadow: 1px 1px 0px rgb(239, 236, 236);">TECHNICIAN LIST</h1>
    <div class="text-end mb-4">
        <a [routerLink]="['/admin/users/add']" class="btn btn-success">Add Technician</a>
    </div>

    <ng-container *ngIf="user$ | async as users">
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="col-2">Name</th>
                        <th scope="col" class="col-5">Email</th>
                        <th scope="col" class="col-2">Phone</th>
                        <th scope="col" class="col-2">Is Admin</th>
                        <th scope="col" class="col-1"> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.phone }}</td>
                        <td>{{ user.isAdmin }}</td>
                        <td><a class="btn btn-light" [routerLink]="['/admin/users', user.id]">
                            Edit
                        </a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
</div>

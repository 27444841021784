<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <div class="navbar-brand" href="#">
      <img src="assets\CP_PLUS.png" alt="Logo" style="height: 30px; margin-right: 10px;">
      
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li> -->
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li> -->
        <li class="nav-item" *ngIf="user !== undefined && (user?.roles?.includes('Reader') || user?.roles?.includes('Writer'))">
          <a class="nav-link" routerLink="/admin/tickets"><strong>TICKETS</strong></a>
      </li>
      <li class="nav-item" *ngIf="user !== undefined && (user?.roles?.includes('Reader') || user?.roles?.includes('Writer'))">
          <a class="nav-link" routerLink="/admin/customers"><strong>CUSTOMERS</strong></a>
      </li>
        <ng-container *ngIf="user !== undefined && user?.roles?.includes('Writer')">
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/users"><strong>TECHNICIANS</strong></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/history"><strong>HISTORY</strong></a>
          </li>
        </ng-container>

      </ul>

      <div class="d-flex">
        <ng-container *ngIf="user === undefined; else showLogout">
          <a [routerLink]="['/login']" class="btn btn-primary">Login</a>
        </ng-container>
        <ng-template #showLogout>
          <div class="d-flex align-items-center">
            <span>{{user?.email}}</span>
            <button class="btn btn-danger ms-3" (click)="onLogout()"> LogOut</button>
          </div>
        </ng-template>
      </div>
      <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
    </div>
  </div>
</nav>
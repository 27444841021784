<div class="container">
    <h1 class="text-center my-5" style="color: #ef383c; font-family: 'Times New Roman', serif; font-size: 3em; text-shadow: 1px 1px 0px rgb(244, 242, 242);">CUSTOMER LIST</h1>

    <div class="d-flex justify-content-between mt-3 mb-3">
        <div class="d-flex">
            <input type="text" class="form-control" placeholder="Search by Name/Phone" #queryText>
            <button type="button" class="btn btn-warning ms-3" (click)="onSearch(queryText.value)">Search</button>
        </div>

        <a [routerLink]="['/admin/customers/add']" class="btn btn-success"> <i class="bi bi-plus-lg"></i> Add Customer</a>
    </div>

    <ng-container *ngIf="customers$ | async as customers">
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="col-2">Name
                            <span>
                                <button type="button " class="btn btn-light btn-sort" (click)="sort('name','asc')">
                                    <i class="bi bi-arrow-up"></i>
                                </button>
                                <button type="button " class="btn btn-light btn-sort" (click)="sort('name','desc')">
                                    <i class="bi bi-arrow-down"></i>
                                </button>
                            </span>
                        </th>
                        <th scope="col" class="col-5">Address</th>
                        <th scope="col" class="col-2">Phone</th>
                        <th scope="col" class="col-2">Alternate Phone</th>
                        <th scope="col" class="col-1"> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let customer of customers">
                        <td>{{ customer.name }}</td>
                        <td>{{ customer.address }}</td>
                        <td>{{ customer.phone }}</td>
                        <td>{{customer.alternatePhone}}</td>
                        <td><a class="btn btn-light" [routerLink]="['/admin/customers', customer.id]">
                            Edit
                        </a></td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex flex-column align-items-center">
                <nav aria-label="Page navigation example" class="mb-3">
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" (click)="getPreviousPage()">Previous</a></li>
                        <li *ngFor="let item of list; let index = index" class="page-item">
                            <a class="page-link" (click)="getPage(index + 1)">{{index + 1}}</a>
                        </li>
                        <li class="page-item"><a class="page-link" (click)="getNextPage()">Next</a></li>
                    </ul>
                </nav>
            
                <span class="badge bg-primary align-items-center custom-badge mb-3" style="font-size: 20px;">Total customers : {{totalCount}}</span>

            </div>
        </div>
    </ng-container>
</div>

import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TicketService } from '../services/ticket.service';
import { response } from 'express';
import { Ticket } from '../models/ticket.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { TicketStatus } from '../models/ticket-status.enum';
import { UserService } from '../../user/service/user.service';
import { User } from '../../user/model/user.model';
import { extraFilters } from '../models/extraFilters';
import { LoaderComponent } from "../../loader/loader.component";
interface ColumnSelection {
  [key: string]: boolean; // Index signature
}
@Component({
    selector: 'app-ticket-list',
    standalone: true,
    templateUrl: './ticket-list.component.html',
    styleUrl: './ticket-list.component.css',
    imports: [RouterLink, CommonModule, FormsModule, LoaderComponent]
})

export class TicketListComponent implements OnInit{

  tickets$? : Observable<Ticket[]>;
  totalCount?:number;
  pageNumber = 1;
  pageSize = 20;
  isNewStatusFilterActive: boolean = false;
  isProgressStatusFilterActive: boolean = false;
  isCancelledStatusFilterActive: boolean = false;
  isClosedStatusFilterActive: boolean = false;
  isTicketConfirmedStatusActive : boolean = false;
  list: number[] = [];
  searchQuery?: string;
  isLoading = false;
  status?:number[] = [];
  ticketCount$?: Observable<number>;
  technicians: User[] = [];
  columnsList = ['Id', 'Customer', 'Query', 'Technician', 'Status', 'CreatedAt', 'UpdatedAt', 'Payment', 'Otp','Scheduled', 'Address'];
  selectedColumns: ColumnSelection = {
    Id: true,
    Customer: true,
    Query: true,
    Technician: true,
    Status: true,
    CreatedAt: true,
    UpdatedAt: false,
    Payment:false,
    Otp: false,
    Scheduled:false
  };
  extrafilters: extraFilters = {
    paymentFilter: '',
    otpVerifiedFilter: ''
  };
  ispaymentyes: boolean = false;
  ispaymentno: boolean = false;
  selectedUser: string = '';
  constructor(private ticketService : TicketService,private  userService: UserService){

  }
  ngOnInit(): void {
    this.isLoading = true;
    this.ticketService.getTicketCount()
    .subscribe({
      next: (value) =>{
        this.totalCount = value;
        this.list = new Array(Math.ceil(value/this.pageSize));
        this.tickets$ = this.ticketService.getAllTickets(undefined,undefined,
          undefined,this.pageNumber, this.pageSize);
        this.ticketCount$ = this.tickets$.pipe(
            map(tickets => tickets.length))
      }
    })
    this.userService.getAllUsers()
    .subscribe({
      next:(value) =>{
        this.technicians = value;
        this.isLoading = false;
      }
    })
  }
  onSearch(query:string){
    this.searchQuery = query;
    this.tickets$ = this.ticketService.getAllTickets(this.searchQuery, undefined, undefined, 
      this.pageNumber, this.pageSize, this.status, this.selectedUser, this.extrafilters);
    this.ticketCount$ = this.tickets$.pipe(
      map(tickets => tickets.length))
  }

  onFilterByStatus(status?:number){

    if(status!=undefined){
      const index = this.status?.indexOf(status);
      if (index === -1 ) {
          this.status?.push(status); 
      } else {
        if(index != undefined)
          this.status?.splice(index, 1); 
      }
    }
    if(status==0)
    this.isNewStatusFilterActive = !this.isNewStatusFilterActive;
    if(status==1)
      this.isProgressStatusFilterActive = !this.isProgressStatusFilterActive;
    if(status==2)
      this.isClosedStatusFilterActive = !this.isClosedStatusFilterActive;
    if(status==3)
    this.isCancelledStatusFilterActive = !this.isCancelledStatusFilterActive;
    if(status==4)
    this.isTicketConfirmedStatusActive = !this.isTicketConfirmedStatusActive;
    this.tickets$ = this.ticketService.getAllTickets(this.searchQuery, undefined, undefined, this.pageNumber, 
      this.pageSize, this.status, this.selectedUser, this.extrafilters );
    this.ticketCount$ = this.tickets$.pipe(
      map(tickets => tickets.length))
  }
  sort(sortBy: string, sortDirection: string){
    this.tickets$ = this.ticketService.getAllTickets(this.searchQuery, sortBy, sortDirection, 
      this.pageNumber, this.pageSize, this.status , this.selectedUser, this.extrafilters);
    this.ticketCount$ = this.tickets$.pipe(
      map(tickets => tickets.length))

  }

  getPage(pageNumber: number){
    this.tickets$ = this.ticketService.getAllTickets(this.searchQuery, undefined, undefined, pageNumber, 
      this.pageSize, this.status, this.selectedUser, this.extrafilters );
    this.ticketCount$ = this.tickets$.pipe(
      map(tickets => tickets.length))

  }

  getNextPage(){
    if(this.pageNumber +1 > this.list.length){
      return;
    }
    this.pageNumber +=1;
    this.tickets$ = this.ticketService.getAllTickets(this.searchQuery, undefined, undefined, this.pageNumber, 
      this.pageSize, this.status, this.selectedUser, this.extrafilters );
    this.ticketCount$ = this.tickets$.pipe(
      map(tickets => tickets.length))

  }

  getPreviousPage(){
    if(this.pageNumber -1 < 1){
      return;
    }
    this.pageNumber -=1;
    this.tickets$ = this.ticketService.getAllTickets(this.searchQuery, undefined, undefined, this.pageNumber, 
      this.pageSize, this.status, this.selectedUser , this.extrafilters);
    this.ticketCount$ = this.tickets$.pipe(
      map(tickets => tickets.length))

  }
  getStatusString(status: number): string {
    return TicketStatus[status];
  }
  filterByTechnician(): void {
    this.tickets$ = this.ticketService.getAllTickets(this.searchQuery, undefined, undefined, this.pageNumber,
       this.pageSize, this.status, this.selectedUser, this.extrafilters);
  }
  filterByPageSize(): void{
    this.ticketService.getTicketCount()
    .subscribe({
      next: (value) =>{
        this.totalCount = value;
        this.list = new Array(Math.ceil(value/this.pageSize));
        this.tickets$ = this.ticketService.getAllTickets(undefined,undefined,
          undefined,this.pageNumber, this.pageSize);
        this.ticketCount$ = this.tickets$.pipe(
            map(tickets => tickets.length))
      }
    })
  }
  togglePaymentFilter(filterType: 'yes' | 'no') {
    if (filterType === 'yes') {
      this.ispaymentyes = !this.ispaymentyes;
      if (this.ispaymentyes) {
        this.ispaymentno = false;
      }
    } else if (filterType === 'no') {
      this.ispaymentno = !this.ispaymentno;
      if (this.ispaymentno) {
        this.ispaymentyes = false;
      }
    }
  
    if (this.extrafilters) {
      if (this.ispaymentyes) {
        this.extrafilters.paymentFilter = 'true';
      } else if (this.ispaymentno) {
        this.extrafilters.paymentFilter = 'false';
      } else {
        this.extrafilters.paymentFilter = undefined; // or null, depending on your requirements
      }
    }
  
    this.tickets$ = this.ticketService.getAllTickets(
      this.searchQuery, 
      undefined, 
      undefined, 
      this.pageNumber, 
      this.pageSize,
      this.status, 
      this.selectedUser, 
      this.extrafilters
    );
  }
  
}

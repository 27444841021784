<div class="container">
    <h1 class="text-center my-5"
        style="color: #ef383c; font-family: 'Times New Roman', serif; font-size: 3em; text-shadow: 1px 1px 0px rgb(240, 235, 235);">
        TICKET LIST</h1>


    <span class="badge bg-danger align-items-center custom-badge mb-3" style="font-size: 20px;">Tickets on this page: {{
        ticketCount$ | async }}</span>

    <div class="container mt-3 mb-3">
        <div class="row">
            <div class="col-lg-6">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Search by Id/Query" #queryText>
                    <button type="button" class="btn btn-light" (click)="onSearch(queryText.value)">
                        <i class="bi bi-search"></i>
                    </button>
                </div>
            </div>
            <div class="col-lg-6 mt-3 mt-lg-0 text-lg-end">
                <button type="button" class="btn btn-danger mx-1" [class.active]="isNewStatusFilterActive"
                    (click)="onFilterByStatus(0)">New</button>
                <button type="button" class="btn btn-success mx-1" [class.active]="isProgressStatusFilterActive"
                    (click)="onFilterByStatus(1)">InProgress</button>
                <button type="button" class="btn btn-dark mx-1" [class.active]="isClosedStatusFilterActive"
                    (click)="onFilterByStatus(2)">Closed</button>
                <button type="button" class="btn btn-info mx-1" [class.active]="isCancelledStatusFilterActive"
                    (click)="onFilterByStatus(3)">Cancelled</button>
                <button type="button" class="btn btn-warning mx-1" [class.active]="isTicketConfirmedStatusActive"
                    (click)="onFilterByStatus(4)" style="background-color: #FFE8C5;"> <i style="color: red;"
                        class="bi bi-star-fill blinking-star"></i>
                    PriorityTicket
                </button>
            </div>
        </div>
        <div class="row mt-3 align-items-center">
            <!-- Add Ticket Button -->
            <div class="col-auto">
                <a [routerLink]="['/admin/tickets/add']" class="btn btn-success">
                    <i class="bi bi-plus-lg"></i> Add Ticket
                </a>
            </div>

            <!-- Column Dropdown -->
            <div class="col-auto ms-3">
                <div class="dropdown">
                    <button class="btn btn-danger dropdown-toggle d-block" type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-plus-circle-dotted"></i> Column
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li *ngFor="let column of columnsList" class="dropdown-item">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" id="{{ column }}"
                                    [(ngModel)]="selectedColumns[column]">
                                <label class="form-check-label ms-2" for="{{ column }}">{{ column }}</label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Select Technician Dropdown -->
            <div class="container">
                <div class="select-container d-flex justify-content-end">
                    <select class="form-select form-select-sm d-inline-block w-auto mr-2" [(ngModel)]="selectedUser"
                        (change)="filterByTechnician()">
                        <option value="">Select Technician</option>
                        <option *ngFor="let tech of technicians" [value]="tech.id">{{ tech.name }}</option>
                    </select>
                    <select class="form-select form-select-sm d-inline-block w-auto" [(ngModel)]="pageSize"
                        (change)="filterByPageSize()">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>



        </div>


    </div>

    <br>
    <app-loader [isLoading]="isLoading"></app-loader>

    <ng-container *ngIf="tickets$ | async as tickets">
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['Id']">Id
                            <span>
                                <button type="button " class="btn btn-light btn-sort" (click)="sort('id','asc')">
                                    <i class="bi bi-arrow-up"></i>
                                </button>
                                <button type="button " class="btn btn-light btn-sort" (click)="sort('id','desc')">
                                    <i class="bi bi-arrow-down"></i>
                                </button>
                            </span>
                        </th>
                        <th scope="col" class="col-2" *ngIf="selectedColumns['Customer']">Customer</th>
                        <th scope="col" class="col-2" *ngIf="selectedColumns['Address']">Customer Address</th>
                        <th scope="col" class="col-3" *ngIf="selectedColumns['Query']">Query</th>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['Technician']">
                            Technician
                        </th>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['Status']">Status</th>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['Payment']">
                            <div class="d-flex align-items-center">
                                <span class="me-2">Payment Done</span>
                                <div class="btn-group">
                                    <button type="button" class="btn btn-sm btn-outline-primary"
                                        (click)="togglePaymentFilter('yes')" [class.active]="ispaymentyes">
                                        Yes
                                    </button>
                                    <button type="button" class="btn btn-sm btn-outline-primary"
                                        (click)="togglePaymentFilter('no')" [class.active]="ispaymentno">
                                        No
                                    </button>
                                </div>
                            </div>
                        </th>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['Otp']">OTP verified</th>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['Scheduled']">Scheduled Date</th>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['CreatedAt']">Created At</th>
                        <th scope="col" class="col-1" *ngIf="selectedColumns['UpdatedAt']">Updated At</th>

                        <th scope="col" class="col-1"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ticket of tickets">
                        <td *ngIf="selectedColumns['Id']">{{ticket.displayId}}</td>
                        <td *ngIf="selectedColumns['Customer']">
                            <b style="font-size: larger;">{{ticket.customer.name}}</b>
                        </td>
                        <td *ngIf="selectedColumns['Address']">
                            {{ ticket.customer.address }}
                        </td>
                        <td *ngIf="selectedColumns['Query']">{{ ticket.description | slice:0:170 }}</td>
                        <td *ngIf="selectedColumns['Technician']">
                            <span class="badge badge-pill badge-light badge-user">{{ticket.user.name}}</span>
                        </td>
                        <td *ngIf="selectedColumns['Status']">
                            <span class="badge badge-pill badge-status" [ngClass]="{
                                'text-bg-danger': getStatusString(ticket.status) === 'New',
                                'text-bg-info': getStatusString(ticket.status) === 'Cancelled',
                                'text-bg-success': getStatusString(ticket.status) === 'InProgress',
                                'text-bg-dark': getStatusString(ticket.status) === 'Closed',
                                'priority-ticket ': getStatusString(ticket.status) === 'PriorityTicket'   
                            }">
                                <i *ngIf="getStatusString(ticket.status) === 'PriorityTicket'"
                                    style="color:red;" class="bi bi-star-fill blinking-star"></i>
                                {{ getStatusString(ticket.status) }}
                            </span>
                        </td>
                        <td *ngIf="selectedColumns['Payment']">
                            {{ ticket.isPaymentDone ? 'TRUE' : 'FALSE' }}
                        </td>
                        <td *ngIf="selectedColumns['Otp']">
                            {{ ticket.isOtpVerified ? 'TRUE' : 'FALSE' }}
                        </td>
                        <td *ngIf="selectedColumns['Scheduled']">
                            {{ ticket.scheduledDate | date:'dd MMMM yyyy' }}
                        </td>
                        <td *ngIf="selectedColumns['CreatedAt']">
                            {{ ticket.createdAt | date:'dd MMMM yyyy' }}<br>{{ ticket.createdAt | date:'h:mm a' }}
                        </td>
                        <td *ngIf="selectedColumns['UpdatedAt']">
                            {{ ticket.updatedAt | date:'dd MMMM yyyy' }}<br>{{ ticket.updatedAt | date:'h:mm a' }}
                        </td>

                        <td>
                            <a class="btn btn-outline-primary btn-edit"
                                [routerLink]="['/admin/tickets', ticket.id]">Edit</a>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="d-flex flex-column align-items-center">
                <nav aria-label="Page navigation example" class="mb-3">
                    <ul class="pagination">
                        <li class="page-item"><a class="page-link" (click)="getPreviousPage()">Previous</a></li>
                        <li *ngFor="let item of list; let index = index" class="page-item">
                            <a class="page-link" (click)="getPage(index + 1)">{{index + 1}}</a>
                        </li>
                        <li class="page-item"><a class="page-link" (click)="getNextPage()">Next</a></li>
                    </ul>
                </nav>

                <span class="badge bg-danger align-items-center custom-badge mb-3" style="font-size: 20px;">Total
                    Tickets : {{totalCount}}</span>

            </div>

        </div>
    </ng-container>
</div>

<div class="container">
    <h1 class="mt-3">Add Technician</h1>

    <form #form="ngForm" (ngSubmit)="OnFormSubmit()" autocomplete="off">

        <div class="mt-2 mb-3">

            <div class="mt-3">
                <label for="userName" class="form-label"> UserName </label>
                <input type="text" id="userName" class="form-control" name="userName" [(ngModel)]="model.userName">
            </div>

            <div class="mt-3">
                <label for="email" class="form-label"> Email </label>
                <textarea id="email" class="form-control" name="email" [(ngModel)]="model.email" ></textarea>
            </div>

            <div class="mt-3">
                <label for="phone" class="form-label"> Phone </label>
                <input type="number" id="phone" class="form-control" name="phone" [(ngModel)]="model.phone" >
            </div>

            <div class="mt-3">
                <label for="imageUrl" class="form-label"> Password </label>
                <input type="password" id="imageUrl" class="form-control" name="imageUrl" [(ngModel)]="model.password">
            </div>

            <div class="mt-3">
                <input type="checkbox" id="isAdmin" class="form-check-input" name="isAdmin" [(ngModel)]="model.isAdmin">
                <label for="isAdmin" class="form-check-label">Is Admin</label>
            </div>

            <div class="mt-3">
                <button class="btn btn-primary" type="submit">Save</button>
            </div>
            
        </div>
        
    </form>
</div>

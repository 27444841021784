import { Routes } from '@angular/router';
import { TicketListComponent } from './features/ticket/ticket-list/ticket-list.component';
import { AddTicketComponent } from './features/ticket/add-ticket/add-ticket.component';
import { EditTicketComponent } from './features/ticket/edit-ticket/edit-ticket.component';
import { CustomerListComponent } from './features/customer/customer-list/customer-list.component';
import { AddCustomerComponent } from './features/customer/add-customer/add-customer.component';
import { EditCustomerComponent } from './features/customer/edit-customer/edit-customer.component';
import { LoginComponent } from './features/auth/login/login.component';
import { authGuard } from './features/auth/guard/auth.guard';
import { UserListComponent } from './features/user/user-list/user-list/user-list.component';
import { AddUserComponent } from './features/user/add-user/add-user/add-user.component';
import { EditUserComponent } from './features/user/edit-user/edit-user.component';
import { TicketHistoryListComponent } from './features/ticket-history/ticket-history-list/ticket-history-list/ticket-history-list.component';

export const routes: Routes = [
  { 
    path: 'admin/tickets',
    component:  TicketListComponent,
    canActivate : [authGuard]

  },
  {
    path : '',
    component : LoginComponent
  },
  {
    path : 'login',
    component : LoginComponent
  },
  {
    path : 'admin/tickets/add',
    component : AddTicketComponent,
    canActivate : [authGuard]
  },
  {
    path : 'admin/tickets/:id',
    component : EditTicketComponent,
    canActivate : [authGuard]

  },
  {
    path : 'admin/customers',
    component : CustomerListComponent,
    canActivate : [authGuard]

  },
  {
    path : 'admin/history',
    component : TicketHistoryListComponent,
    canActivate : [authGuard]

  },
  {
    path : 'admin/users',
    component : UserListComponent,
    canActivate : [authGuard]

  },
  {
    path : 'admin/customers/add',
    component : AddCustomerComponent,
    canActivate : [authGuard]

  },
  {
    path : 'admin/users/add',
    component : AddUserComponent,
    canActivate : [authGuard]

  },
  {
    path : 'admin/customers/:id',
    component : EditCustomerComponent,
    canActivate : [authGuard]

  },
  {
    path : 'admin/users/:id',
    component : EditUserComponent,
    canActivate : [authGuard]

  }
  
];


import { Component } from '@angular/core';
import { AddUser } from '../../model/add-user.model';
import { UserService } from '../../service/user.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-add-user',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './add-user.component.html',
  styleUrl: './add-user.component.css'
})
export class AddUserComponent {
  model : AddUser;

  constructor(private userService: UserService, private router:Router){
    this.model = {
      userName: '',
      email : '',
      phone : '',
      password : '',
      isAdmin : false
    }
  }

  OnFormSubmit(): void{
    this.model.phone = this.model.phone.toString();
    this.userService.createUser(this.model)
    .subscribe({
      next : (response) =>{
        this.router.navigateByUrl('admin/users');
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../model/user.model';
import { UserService } from '../../service/user.service';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-list',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './user-list.component.html',
  styleUrl: './user-list.component.css'
})
export class UserListComponent implements OnInit {
  user$ : Observable<User[]> | undefined;

  constructor(private userService : UserService){

  }

  ngOnInit(): void {
    this.user$ = this.userService.getAllUsers();
  }
}

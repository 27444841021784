import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CustomerService } from '../service/customer.service';
import { Observable } from 'rxjs';
import { Customer } from '../model/customer.model';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-customer-list',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './customer-list.component.html',
  styleUrl: './customer-list.component.css'
})
export class CustomerListComponent implements OnInit {

  customers$ : Observable<Customer[]> | undefined;
  searchQuery?: string;
  totalCount?:number;
  pageNumber = 1;
  pageSize = 10;
  list: number[] = [];

  constructor(private customerService : CustomerService){

  }

  ngOnInit(): void {
    this.customerService.getCustomerCount()
    .subscribe({
      next: (value) =>{
        this.totalCount = value;
        this.list = new Array(Math.ceil(value/this.pageSize));
        this.customers$ = this.customerService.getAllCustomers(undefined,undefined,
          undefined,this.pageNumber, this.pageSize);
      }
    })
  }

  onSearch(query:string){
    this.searchQuery = query;
    this.customers$ = this.customerService.getAllCustomers(this.searchQuery);
  }

  sort(sortBy: string, sortDirection: string){
    this.customers$ = this.customerService.getAllCustomers(this.searchQuery, sortBy, sortDirection);
  }
  getPage(pageNumber: number){
    this.customers$ = this.customerService.getAllCustomers(this.searchQuery, undefined, undefined, pageNumber, this.pageSize );

  }
  getNextPage(){
    if(this.pageNumber +1 > this.list.length){
      return;
    }
    this.pageNumber +=1;
    this.customers$ = this.customerService.getAllCustomers(this.searchQuery, undefined, undefined, this.pageNumber, this.pageSize );

  }

  getPreviousPage(){
    if(this.pageNumber -1 < 1){
      return;
    }
    this.pageNumber -=1;
    this.customers$ = this.customerService.getAllCustomers(this.searchQuery, undefined, undefined, this.pageNumber, this.pageSize );

  }
}

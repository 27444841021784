import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomerService } from '../service/customer.service';
import { Customer } from '../model/customer.model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UpdateCustomerRequest } from '../model/update-ticket-request.model';
import { response } from 'express';
import { LoaderComponent } from "../../loader/loader.component";

@Component({
    selector: 'app-edit-customer',
    standalone: true,
    templateUrl: './edit-customer.component.html',
    styleUrl: './edit-customer.component.css',
    imports: [FormsModule, CommonModule, LoaderComponent]
})
export class EditCustomerComponent implements OnInit, OnDestroy{
  id: string | null = null;
  routeSubscription? : Subscription;
  model? : Customer;
  editCustomerSubscription? : Subscription;
  deleteCustomerSubscription? : Subscription;
  isLoading = false;


  constructor(private route:ActivatedRoute, 
    private customerService : CustomerService,
    private router : Router){

  }
  

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe({
      next:(params) =>{
        this.id = params.get('id');
        //get customer from API
        if(this.id)
        this.customerService.getCustomerById(this.id).subscribe({
          next:(response) =>{
            this.model = response;
          }
        });
      }
    });
  }


  OnFormSubmit() : void{
    this.isLoading = true;
    const updateCustomerRequest : UpdateCustomerRequest = {
      name: this.model?.name ?? '',
      address: this.model?.address ?? '',
      phone: this.model?.phone.toString() ?? '',
      alternatePhone: this.model?.alternatePhone.toString() ?? '',
      imageUrl : this.model?.imageUrl ??''
    };
    if(this.id)
    this.editCustomerSubscription =  this.customerService.updateCustomer(this.id, updateCustomerRequest).subscribe({
      next :(response) => {
        this.router.navigateByUrl('/admin/customers');
        this.isLoading = false;
      }
  });
  }
  onDelete():void{
    if(this.id){
      //call service and delete customer
      this.deleteCustomerSubscription = this.customerService.deleteCustomer(this.id)
      .subscribe({
        next : (response) =>{
          this.router.navigateByUrl('/admin/customers');
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.editCustomerSubscription?.unsubscribe();
    this.deleteCustomerSubscription?.unsubscribe();
  }

}

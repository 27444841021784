import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AddTicketRequest } from '../models/add-ticket-request.model';
import { TicketService } from '../services/ticket.service';
import { Observable, Subscription, catchError, of } from 'rxjs';
import { Router } from '@angular/router';
import { CustomerService } from '../../customer/service/customer.service';
import { Customer } from '../../customer/model/customer.model';
import { CommonModule } from '@angular/common';
import { UserService } from '../../user/service/user.service';
import { User } from '../../user/model/user.model';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DatePipe } from '@angular/common';
import { LoaderComponent } from "../../loader/loader.component";

@Component({
    selector: 'app-add-ticket',
    standalone: true,
    templateUrl: './add-ticket.component.html',
    styleUrl: './add-ticket.component.css',
    providers: [DatePipe],
    imports: [FormsModule, CommonModule, MatDatepickerModule, MatNativeDateModule, MatInputModule,
        MatFormFieldModule, LoaderComponent]
})
export class AddTicketComponent implements OnDestroy, OnInit {

    model: AddTicketRequest;
    customers$?: Observable<Customer[]>;
    users$?: Observable<User[]>;
    private addTicketSubscription?: Subscription;
    showNewCustomerForm: boolean = false;
    selectedCustomer: Customer | null = null;
    filteredCustomers: Customer[] = [];
    searchTerm: string = '';
    isLoading = false;
    newCustomer: Customer = { name: '', address: '', phone: '', id: '', imageUrl: '', alternatePhone: '' }; // Object to hold new customer details
    constructor(private ticketService: TicketService,
        private router: Router,
        private customerService: CustomerService,
        private userService: UserService,
        private datePipe: DatePipe) {
        this.model = {
            title: '',
            displayId: '',
            description: '',
            customerId: '',
            userId: '',
            notifyCustomer: false,
            notifyUser: false,
            visitingCharge: false,
            visitingChargeAmount: 0,
            scheduledDate: undefined
        }
    }

    ngOnInit(): void {
        this.customers$ = this.customerService.getAllCustomers();
        this.users$ = this.userService.getAllUsers();
        this.isLoading = false;
    }

    OnFormSubmit(form: any) {
        this.isLoading = true;
        if(this.selectedCustomer)
        this.model.customerId = this.selectedCustomer.id;
        if (form.valid) {
            this.addTicketSubscription = this.ticketService.AddTicket(this.model)
                .subscribe({
                    next: (response) => {
                        this.router.navigateByUrl('/admin/tickets');
                        this.isLoading = false;
                    }
                });
        }
        else this.isLoading = false;
    }
   

    saveNewCustomer(form: any) {
        if (form.valid) {
            // Ensure phone is a string
            this.newCustomer.phone = this.newCustomer?.phone.toString();
            this.isLoading = true;
            // Call your service method to save the new customer
            this.customerService.createCustomer(this.newCustomer)
                .pipe(
                    catchError((error: any) => {
                        let errorMessage = 'An unexpected error occurred';
                        this.isLoading = false;

                        // Check if the error status is 400 and the error contains customer details
                        if (error.status === 400 && error.error) {
                            const existingCustomer = error.error;
                            errorMessage = `This customer already exists. Here are the existing customer details:\nName: ${existingCustomer.name}\nPhone: ${existingCustomer.phone}\nAddress: ${existingCustomer.address}`;
                        } else {
                            errorMessage = `An unexpected error occurred: ${error.message}`;
                        }

                        alert(errorMessage);
                        return of(null); // Return a safe value or handle it appropriately
                    })
                )
                .subscribe({
                    next: (response) => {
                        if (response) {
                            // After successfully saving the new customer, update the customers list
                            this.customers$ = this.customerService.getAllCustomers();
                            // Reset newCustomer object and hide the form
                            this.newCustomer = { name: '', address: '', phone: '', id: '', imageUrl: '', alternatePhone: '' };
                            this.showNewCustomerForm = false;
                            this.isLoading = false;
                        }
                    }
                });

        } else {
            alert('Form is not valid. Please correct the errors and try again.');
            this.isLoading = false;
        }
    }
    filterCustomers(): void {
        this.selectedCustomer=null;
        if (!this.searchTerm && this.customers$) {

            this.customers$.subscribe(customers => {
                this.filteredCustomers = customers;
            });
            return;
        }
        if (this.customers$) {
            this.customers$.subscribe(customers => {
                this.filteredCustomers = customers.filter(customer =>
                    customer.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            });
        }

    }
    onCustomerSelectionChange(event: any): void {
        const selectedValue = event.target.value;
        const selectedId = selectedValue.split(': ')[1]; // Extract the actual ID
        if (selectedValue === 'newCustomer') {
            this.showNewCustomerForm = true;
            this.selectedCustomer = null;
        } else {
            this.showNewCustomerForm = false;
            if (this.customers$) {
                this.customers$.subscribe(customers => {
                    this.selectedCustomer = customers.find(customer => customer.id === selectedId) || null;
                });
            }
        }
    }
    
    addCustomer():void{
        console.log(this.searchTerm);
        this.showNewCustomerForm = !this.showNewCustomerForm;
        this.selectedCustomer = null;
    }

    selectCustomer(customer: Customer): void {
        this.selectedCustomer = customer;
        this.searchTerm = customer.name;
        if (this.customers$) {
            this.customers$.subscribe(customers => {
                this.selectedCustomer = customers.find(customer => customer.id === this.selectedCustomer?.id) || null;
            });
        } 
        // Optionally, set the search box to the selected customer's name
    }
    setScheduledDate(event: any) {
        const selectedDate = new Date(event.value);
        // Convert to IST (UTC+5:30)
        const istDate = new Date(selectedDate.getTime() + (5.5 * 60 * 60 * 1000));
        this.model.scheduledDate = new Date(
            istDate.getFullYear(),
            istDate.getMonth(),
            istDate.getDate(),
            istDate.getHours(),
            istDate.getMinutes()
        );
    }
    ngOnDestroy(): void {
        this.addTicketSubscription?.unsubscribe();
    }
}

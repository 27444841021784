<div class="container">
    <div class="mt-5">
        <div class="col-12 col-md-6 col-lg-4 mx-auto">
            <h1>Login</h1>

            <form (ngSubmit) = "onFormSubmit()">
                <div class="mt-3">
                    <label for="form-label">Email</label>
                    <input type="text" class="form-control" id="email" name="email" 
                    [(ngModel)] = "model.email">
                </div>
                <div class="mt-3">
                    <label for="form-label">Password</label>
                    <input type="password" class="form-control" id="password" name="password"
                    [(ngModel)] = "model.password">
                </div>
                <div class="mt-3" style="display: flex; align-items: center;">
                  <button class="btn btn-primary" type="submit" [disabled]="isLoading">Login</button>
                  <app-loader [isLoading]="isLoading"></app-loader>
              </div>
            </form>        
        </div>
    </div>
</div>

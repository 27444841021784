import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TicketService } from '../services/ticket.service';
import { Ticket } from '../models/ticket.model';
import { CommonModule, formatDate } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UpdateTicketRequest } from '../models/update-ticket-request.model';
import { CustomerService } from '../../customer/service/customer.service';
import { Customer } from '../../customer/model/customer.model';
import { TicketStatus } from '../models/ticket-status.enum';
import { User } from '../../user/model/user.model';
import { UserService } from '../../user/service/user.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { tick } from '@angular/core/testing';
import { whatsappMessage } from '../models/whatsappmessage.model';
import { SwitcherComponent } from '../../switcher/switcher.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoaderComponent } from "../../loader/loader.component";
@Component({
    selector: 'app-edit-ticket',
    standalone: true,
    templateUrl: './edit-ticket.component.html',
    styleUrl: './edit-ticket.component.css',
    providers: [BsModalService],
    imports: [CommonModule, FormsModule, SwitcherComponent, MatDatepickerModule, MatNativeDateModule, MatInputModule,
        MatFormFieldModule, LoaderComponent]
})
export class EditTicketComponent implements OnInit, OnDestroy {

  id: string | null = null;
  paramsSubscription?: Subscription;
  isSubmitButtonDisabled: boolean = false;
  showTechnician: boolean = false;
  showOTP: boolean = false;
  showCancelledReason: boolean = false;
  editTicketSubscription?: Subscription;
  ticket?: Ticket;
  receivedSwitcherState: boolean = false;
  customers$?: Observable<Customer[]>;
  user$?: Observable<User[]>;
  selectedCustomer?: string;
  selectedUser?: string;
  ticketStatus = TicketStatus;
  notifyUser?: boolean;
  originalStatus: any;
  lastChangedStatus: any;
  modalRef?: BsModalRef;
  isLoading = false;
  whatsappMsg: whatsappMessage = {
    informUserOfTicketCreation: false,
    informCustomerofTicketStatusChangedNewToInProgress: false,
    SendOTPToCustomer: false,
    InformCustomerTicketClosed: false,
    InformCustomerTicketCancelled: false,
    informUserTicketCancelled : false,
    informUserTicketClosed: false
  };
  keys = Object
    .keys(this.ticketStatus)
    .filter((v) => isNaN(Number(v)));

  informUserOfTicketCreationBox?: boolean = false;
  informCustomerofTicketStatusChangedNewToInProgressBox?: boolean = false;
  SendOTPToCustomerBox?: boolean = false;
  InformCustomerTicketClosedBox?: boolean = false;
  InformCustomerTicketCancelledBox?: boolean = false;
  informUserTicketCancelledBox?: boolean = false;
  InformUserTicketClosedBox?: boolean = false;
  UserOTP?: string;
  isOTPVerified: boolean = false;



  constructor(private route: ActivatedRoute,
    private ticketService: TicketService,
    private router: Router,
    private customerService: CustomerService,
    private userService: UserService,
    private modalService: BsModalService) {

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.customers$ = this.customerService.getAllCustomers();
    this.user$ = this.userService.getAllUsers();
    this.paramsSubscription = this.route.paramMap.subscribe({
      next: (params) => {
        this.id = params.get('id');
        if (this.id) {
          this.ticketService.getTicketById(this.id)
            .subscribe({
              next: (response) => {
                this.ticket = response;
                this.selectedCustomer = response.customer.id;
                this.originalStatus = this.ticket?.status;
                this.selectedUser = response.user.id;
                this.receivedSwitcherState = response.isPaymentDone;
                this.whatsappMsg = {
                  informUserOfTicketCreation: false,
                  informCustomerofTicketStatusChangedNewToInProgress: false,
                  SendOTPToCustomer: false,
                  InformCustomerTicketClosed: false,
                  InformCustomerTicketCancelled: false,
                  informUserTicketCancelled : false,
                  informUserTicketClosed : false
                };
                if (this.ticket?.status == TicketStatus.InProgress) {
                  this.isSubmitButtonDisabled = false;
                  this.showTechnician = true;
                  this.keys = this.keys.filter(value => value !== "New");
                }
                if (this.ticket?.status == TicketStatus.PriorityTicket) {
                  this.isSubmitButtonDisabled = false;
                  this.showTechnician = true;
                  this.keys = this.keys.filter(value => value !== "New");
                }
                if (this.ticket?.status == TicketStatus.Closed) {
                  this.isSubmitButtonDisabled = true;
                  this.showTechnician = true;
                  if (this.ticket?.isOtpVerified == false)
                    this.showOTP = true;
                  this.keys = this.keys.filter(value => value !== "New");
                  this.keys = this.keys.filter(value => value !== "InProgress");
                  this.keys = this.keys.filter(value => value !== "PaymentDone");
                  this.keys = this.keys.filter(value => value !== "Cancelled");

                }
                if (this.ticket?.status == TicketStatus.New) {
                  this.isSubmitButtonDisabled = false;
                  this.showTechnician = false;
                  this.showOTP = false;
                  this.showCancelledReason = false;
                  this.keys = this.keys.filter(value => value !== "Closed");
                }
                if (this.ticket?.status == TicketStatus.Cancelled) {
                  this.isSubmitButtonDisabled = true;
                  this.showTechnician = false;
                  this.showOTP = false;
                  this.showCancelledReason = true;
                  this.keys = this.keys.filter(value => value !== "Closed");
                  this.keys = this.keys.filter(value => value !== "InProgress");
                  this.keys = this.keys.filter(value => value !== "PaymentDone");
                  this.keys = this.keys.filter(value => value !== "New");
                }
                if (this.ticket?.visitingCharge == undefined)
                  this.ticket.visitingCharge = 0;

                this.isLoading = false;
                
              }
            })
        }
      }
    });
  }

  OnFormSubmit(form: any): void {
    this.modalRef?.hide();
    this.isLoading = true;
    if (form.valid) {
      const updateTicketRequest: UpdateTicketRequest = {
        title: this.ticket?.title ?? '',
        description: this.ticket?.description ?? '',
        customerId: this.selectedCustomer ?? '',
        status: this.ticket?.status ?? 0,
        userId: this.selectedUser ?? '',
        createdAt: this.ticket?.createdAt ?? new Date(),
        notifyUser: false,
        whatsappMsg: this.whatsappMsg ?? {
          informUserOfTicketCreation: false,
          informCustomerofTicketStatusChangedNewToInProgress: false,
          SendOTPToCustomer: false,
          InformCustomerTicketClosed: false
        },
        isPaymentDone: this.receivedSwitcherState ?? false,
        CancellationReason: this.ticket?.cancellationReason ?? '',
        scheduledDate: this.ticket?.scheduledDate ?? undefined,
        displayId: this.ticket?.displayId ?? ''
      };
      if (this.id) {
        this.editTicketSubscription = this.ticketService.updateTicket(this.id, updateTicketRequest).subscribe({
          next: (response) => {
            this.router.navigateByUrl('/admin/tickets');
            this.isLoading = false;
          }
        });

      }
    }
    else {
      alert('Error Occured');
      this.isLoading = false;
    }
  }

  onDelete() {
    if (this.id) {
      const confirmation = window.confirm('Are you sure you want to delete this ticket?');
      if (confirmation) {
        this.ticketService.deleteTicket(this.id).subscribe({
          next: (response) => {
            this.router.navigateByUrl('/admin/tickets');
          }
        });
      }
    }
  }
  OnStatusChange(event: any) {
    this.showTechnician = false;
    this.showOTP = false;
    this.showCancelledReason = false;

    if (event == "1" || event == "4") {
      this.showTechnician = true;
      this.informCustomerofTicketStatusChangedNewToInProgressBox = true;
      this.informUserOfTicketCreationBox = true;
      this.InformCustomerTicketClosedBox = false;
      this.InformUserTicketClosedBox = false;
      this.InformCustomerTicketCancelledBox = false;
      this.informUserTicketCancelledBox = false;

    }
    if (event == "2") {
      if (this.ticket?.isOtpVerified == false)
        this.showOTP = true;
      this.InformCustomerTicketClosedBox = true;
      this.InformUserTicketClosedBox = true;
      this.informCustomerofTicketStatusChangedNewToInProgressBox = false;
      this.informUserOfTicketCreationBox = false;
      this.InformCustomerTicketCancelledBox = false;
      this.informUserTicketCancelledBox = false;

    }
    if (event == "3") {
      this.showCancelledReason = true;
      this.InformCustomerTicketCancelledBox = true;
      this.InformCustomerTicketClosedBox = false;
      this.InformUserTicketClosedBox = false;
      this.informCustomerofTicketStatusChangedNewToInProgressBox = false;
      this.informUserOfTicketCreationBox = false;
      this.informUserTicketCancelledBox = true;
    }
    this.lastChangedStatus = event;
  }
  openModal(viewUserTemplate: TemplateRef<any>) {

    this.modalRef = this.modalService.show(viewUserTemplate);

  }
  sendOtpToCustomer(template: TemplateRef<any>): void {

    if (this.ticket) {
      this.isLoading = true;
      this.editTicketSubscription = this.ticketService.SendOtpToCustomer(this.ticket)
        .subscribe({
          next: (response) => {
            this.openModal(template);
            if (this.ticket)
              this.ticket.otp = response.otp;
            this.isLoading = false;
          }

        });
    }
  }
  handleSwitcherChange(state: boolean) {
    const confirmation = window.confirm('Are you sure Payment is done?');
    if (confirmation) {
      this.receivedSwitcherState = state;
    }
  }

  verifyOtp(): void {
    if (this.ticket) {
      this.isLoading = true;
      this.editTicketSubscription = this.ticketService.VerifyOTP(this.ticket?.id, this.UserOTP ?? '')
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            //this.openModal(template);
            if (response != null) {
              this.showOTP = false;
              alert('OTP VERIFIED!');
              if (this.ticket)
                this.ticket.isOtpVerified = true;
            }
            else {
              alert('Wrong OTP');
            }
          }
        });
    }
  }
  exitModal = (): void => {
    this.modalRef?.hide();
  };
  setScheduledDate(event: any) {
    const selectedDate = new Date(event.value);
    // Convert to IST (UTC+5:30)
    const istDate = new Date(selectedDate.getTime() + (5.5 * 60 * 60 * 1000));
    if (this.ticket) {
      this.ticket.scheduledDate = new Date(
        istDate.getFullYear(),
        istDate.getMonth(),
        istDate.getDate(),
        istDate.getHours(),
        istDate.getMinutes()
      );
    }
  }

  ngOnDestroy(): void {
    this.paramsSubscription?.unsubscribe();
    this.editTicketSubscription?.unsubscribe();
  }

  formatDate(date: any): string {
    return formatDate(date, 'dd MMMM yyyy h:mm a', 'en-US');
  }
  // Function to get enum value from key
  getValueFromKey(key: string): number {
    return this.ticketStatus[key as keyof typeof TicketStatus];
  }
}

import { Component, Output, EventEmitter, Input } from "@angular/core"; // imported Output and EventEmitter decorators
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-switcher",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./switcher.component.html",
  styleUrl: "./switcher.component.css",
})
export class SwitcherComponent {
  @Input() initialState: boolean = false;
  switcherState: boolean = false;
  @Output() sendSwitcherState = new EventEmitter<boolean>(); // added this
  ngOnInit() {
    this.switcherState = this.initialState; // Set initial state
  }

  toggleSwitcherState() {
    this.switcherState = !this.switcherState;
    console.log(this.switcherState);
    this.sendSwitcherState.emit(this.switcherState); // added this
  }
}
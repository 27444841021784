<div class="container">
    <h1 class="mt-3">Add Customer</h1>

    <form #form="ngForm" (ngSubmit)="OnFormSubmit(form)">

        <div class="mt-2 mb-3">

            <div class="mt-3">
                <label for="name" class="form-label"> Name </label>
                <input type="text" id="name" class="form-control" name="name" [(ngModel)]="model.name">
            </div>

            <div class="mt-3">
                <label for="address" class="form-label"> Address </label>
                <textarea id="address" class="form-control" name="address" [(ngModel)]="model.address"></textarea>
            </div>

            <div class="mt-3">
                <label class="form-label" for="CustomerPhone">Number</label>
                <input type="text" class="form-control" [(ngModel)]="model.phone" name="CustomerPhone" required
                    minlength="10" maxlength="10" pattern="^[0-9]*$">
                <div *ngIf="form.submitted && form.controls['CustomerPhone']?.invalid">
                    <span class="text-danger" *ngIf="form.controls['CustomerPhone']?.errors?.['required']">
                        Customer Phone Number is required.
                    </span>
                    <span class="text-danger" *ngIf="form.controls['CustomerPhone']?.errors?.['minlength']">
                        Phone number must be exactly 10 digits long.
                    </span>
                    <span class="text-danger" *ngIf="form.controls['CustomerPhone']?.errors?.['maxlength']">
                        Phone number must be exactly 10 digits long.
                    </span>
                    <span class="text-danger" *ngIf="form.controls['CustomerPhone']?.errors?.['pattern']">
                        Phone number must contain only digits.
                    </span>
                </div>
                <small class="form-text text-muted">Please provide the 10 digit phone number of the customer
                    (Without country code).</small>
            </div>

            <div class="mt-3">
                <label class="form-label" for="CustomerAlternatePhone">Alternate Number</label>
                <input type="text" class="form-control" [(ngModel)]="model.alternatePhone" name="CustomerAlternatePhone"
                    minlength="10" maxlength="10" pattern="^[0-9]*$">
                <div *ngIf="form.submitted && form.controls['CustomerAlternatePhone']?.invalid">
                    <span class="text-danger" *ngIf="form.controls['CustomerAlternatePhone']?.errors?.['required']">
                        Customer Phone Number is required.
                    </span>
                    <span class="text-danger" *ngIf="form.controls['CustomerAlternatePhone']?.errors?.['minlength']">
                        Phone number must be exactly 10 digits long.
                    </span>
                    <span class="text-danger" *ngIf="form.controls['CustomerAlternatePhone']?.errors?.['maxlength']">
                        Phone number must be exactly 10 digits long.
                    </span>
                    <span class="text-danger" *ngIf="form.controls['CustomerAlternatePhone']?.errors?.['pattern']">
                        Phone number must contain only digits.
                    </span>
                </div>
                <small class="form-text text-muted">Please provide the 10 digit alternate phone number of the customer
                    (Without country code).</small>
            </div>

            <div class="mt-3">
                <label for="imageUrl" class="form-label"> image Url </label>
                <input type="text" id="imageUrl" class="form-control" name="imageUrl" [(ngModel)]="model.imageUrl">
            </div>

            <div class="mt-3" style="display: flex; align-items: center;">
                <button class="btn btn-primary" type="submit" [disabled]="isLoading">Save</button>
                <app-loader [isLoading]="isLoading"></app-loader>
            </div>



        </div>

    </form>
</div>
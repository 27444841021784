import { Component, OnInit } from '@angular/core';
import { TicketHistory } from '../../model/ticketHistory.model';
import { TicketHistoryService } from '../service/ticket-history.service';
import { Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoaderComponent } from '../../../loader/loader.component';
import { HistoryFilters } from '../../model/historyFilters.model';

@Component({
  selector: 'app-ticket-history-list',
  standalone: true,
  imports: [RouterLink, FormsModule, CommonModule, MatDatepickerModule, MatNativeDateModule, MatInputModule,
    MatFormFieldModule, LoaderComponent],
  templateUrl: './ticket-history-list.component.html',
  styleUrl: './ticket-history-list.component.css'
})
export class TicketHistoryListComponent implements OnInit {
  history$: Observable<TicketHistory[]> | undefined;
  model: HistoryFilters;
  errorMessage: string | null = null;
  pageNumber = 1;
  pageSize = 20;
  list: number[] = [];
  totalCount?:number;

  constructor(private ticketHistoryService: TicketHistoryService) {
    this.model = {
      startDate: undefined,
      endDate: undefined
    }
  }

  ngOnInit(): void {
    this.history$ = this.ticketHistoryService.getAllHistory(this.model.startDate, this.model.endDate, this.pageNumber, this.pageSize);

    this.ticketHistoryService.getHistoryCount()
    .subscribe({
      next: (value) =>{
        this.totalCount = value;
        this.list = new Array(Math.ceil(value/this.pageSize));
        this.history$ = this.ticketHistoryService.getAllHistory(this.model.startDate, this.model.endDate, this.pageNumber, this.pageSize);
      }
    })
  }

  setStartDate(event: any) {
    const selectedDate = new Date(event.value);
    // Convert to IST (UTC+5:30)
    const istDate = new Date(selectedDate.getTime() + (5.5 * 60 * 60 * 1000));
    this.model.startDate = new Date(
      istDate.getFullYear(),
      istDate.getMonth(),
      istDate.getDate(),
      istDate.getHours(),
      istDate.getMinutes()
    );
  }
  setEndDate(event: any) {
    const selectedDate = new Date(event.value);
    // Convert to IST (UTC+5:30)
    const istDate = new Date(selectedDate.getTime() + (5.5 * 60 * 60 * 1000));
    this.model.endDate = new Date(
      istDate.getFullYear(),
      istDate.getMonth(),
      istDate.getDate(),
      istDate.getHours(),
      istDate.getMinutes()
    );
  }

  getPage(pageNumber: number){
    this.history$ = this.ticketHistoryService.getAllHistory(this.model.startDate, this.model.endDate, pageNumber, this.pageSize);
  }

  getNextPage(){
    if(this.pageNumber +1 > this.list.length){
      return;
    }
    this.pageNumber +=1;
    this.history$ = this.ticketHistoryService.getAllHistory(this.model.startDate, this.model.endDate, this.pageNumber, this.pageSize);

  }

  getPreviousPage(){
    if(this.pageNumber -1 < 1){
      return;
    }
    this.pageNumber -=1;
    this.history$ = this.ticketHistoryService.getAllHistory(this.model.startDate, this.model.endDate, this.pageNumber, this.pageSize);

  }
  submitDates() {
    this.errorMessage = null; // Reset the error message

    if (!this.model.startDate || !this.model.endDate) {
      this.errorMessage = 'Both start date and end date are required.';
      return;
    }

    if (this.model.startDate > this.model.endDate) {
      this.errorMessage = 'Start date cannot be later than end date.';
      return;
    }

    this.history$ = this.ticketHistoryService.getAllHistory(this.model.startDate, this.model.endDate, this.pageNumber, this.pageSize);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../model/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { UpdateUserRequest } from '../model/update-user-request.model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-edit-user',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.css'
})
export class EditUserComponent implements OnInit, OnDestroy{
  id: string | null = null;
  routeSubscription? : Subscription;
  model? : User;
  editUserSubscription? : Subscription;
  deleteUserSubscription? : Subscription;



  constructor(private route:ActivatedRoute, 
    private userService : UserService,
    private router : Router){

  }
  

  ngOnInit(): void {
    this.routeSubscription = this.route.paramMap.subscribe({
      next:(params) =>{
        this.id = params.get('id');
        //get user from API
        if(this.id)
        this.userService.getUserById(this.id).subscribe({
          next:(response) =>{
            this.model = response;
          }
        });
      }
    });
  }


  OnFormSubmit() : void{
    const updateUserRequest : UpdateUserRequest = {
      name: this.model?.name ?? '',
      email: this.model?.email ?? '',
      phone: this.model?.phone.toString() ?? '',
      isAdmin : this.model?.isAdmin ?? false
    };
    if(this.id)
    this.editUserSubscription =  this.userService.updateUser(this.id, updateUserRequest).subscribe({
      next :(response) => {
        this.router.navigateByUrl('/admin/users');
      }
  });
  }
  onDelete():void{
    if(this.id){
      //call service and delete users
      this.deleteUserSubscription = this.userService.deleteUser(this.id)
      .subscribe({
        next : (response) =>{
          this.router.navigateByUrl('/admin/users');
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
    this.editUserSubscription?.unsubscribe();
    this.deleteUserSubscription?.unsubscribe();
  }

}


<div class="container">
    <h1 class="text-center my-5"
        style="color: #ef383c; font-family: 'Times New Roman', serif; font-size: 3em; text-shadow: 1px 1px 0px rgb(239, 236, 236);">
        HISTORY</h1>



        <div class="container mt-3">
            <div class="d-flex align-items-start">
                <div class="me-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>Start date</mat-label>
                        <input matInput [matDatepicker]="startPicker" placeholder="Choose a date" [(ngModel)]="model.startDate"
                            (dateChange)="setStartDate($event)" name="startDate">
                        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                        <mat-datepicker #startPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                
                <div class="me-3">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>End date</mat-label>
                        <input matInput [matDatepicker]="endPicker" placeholder="Choose a date" [(ngModel)]="model.endDate"
                            (dateChange)="setEndDate($event)" name="endDate">
                        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                        <mat-datepicker #endPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                
                <div>
                    <button mat-raised-button color="primary" (click)="submitDates()" class="btn btn-danger mx-1">Submit Dates</button>
                </div>
            </div>
        </div>
        

    <!-- Error Message Display -->
    <div *ngIf="errorMessage" class="error-message">
        <mat-error>{{ errorMessage }}</mat-error>
    </div>
    <ng-container *ngIf="history$ | async as histories">
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead class="thead-light">
                    <tr>
                        <th scope="col" class="col-2">Ticket ID</th>
                        <th scope="col" class="col-2">Date</th>
                        <th scope="col" class="col-2">Customer Name</th>
                        <th scope="col" class="col-5">Update</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let history of histories">
                        <td><a class="btn btn-outline-primary btn-edit"
                                [routerLink]="['/admin/tickets', history.ticket.id]">{{history.ticket.displayId}}</a>
                        </td>
                        <td><strong>{{ history.updateDate | date:'dd MMMM yyyy' }}<br>{{ history.updateDate | date:'h:mm a' }}
                        </strong></td>
                        <td><strong>{{ history.customer.name }}</strong> , <strong>{{ history.customer.phone }}</strong></td>
                        <td><strong>{{ history.updates }}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex flex-column align-items-center">
            <nav aria-label="Page navigation example" class="mb-3">
                <ul class="pagination">
                    <li class="page-item"><a class="page-link" (click)="getPreviousPage()">Previous</a></li>
                    <li *ngFor="let item of list; let index = index" class="page-item">
                        <a class="page-link" (click)="getPage(index + 1)">{{index + 1}}</a>
                    </li>
                    <li class="page-item"><a class="page-link" (click)="getNextPage()">Next</a></li>
                </ul>
            </nav>
        </div>
    </ng-container>
</div>